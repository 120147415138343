@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0px;
  padding: 0px;
  background: rgba(13, 22, 40, 1);
  font-family: 'Lato';
}

.apiContent > a {
  @apply text-yellow-300;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(../public/fonts/Lato/Lato-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Oswald';
  src: local('Lato'), url(../public/fonts/Oswald/Oswald-VariableFont_wght.ttf) format('opentype');
}

.font-oswald {
  font-family: 'Oswald';
}
.font-lato {
  font-family: 'Lato';
}

.font-inter {
  font-family: 'Inter';
}

.button-animation {
  transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Add this CSS in your stylesheet */
.content-animation-enter {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, max-height 0.3s ease;
}

.content-animation-enter-active {
  opacity: 1;
  max-height: 1000px; /* Adjust the max-height as needed */
}

.content-animation-exit {
  opacity: 1;
  max-height: 1000px; /* Adjust the max-height as needed */
  overflow: hidden;
  transition: opacity 0.3s ease, max-height 0.3s ease;
}

.content-animation-exit-active {
  opacity: 0;
  max-height: 0;
}
